@import './../../../variables.scss';


.agencia-fortalezas{
  ul{
    margin-top: 1rem;
    -moz-column-count: 2;
    -moz-column-gap: 2rem;
    -webkit-column-count: 2;
    -webkit-column-gap: 2rem;
    column-count: 2;
    column-gap: 2rem;

    @include mobile{
      column-count: 1;
    }
  }
}
