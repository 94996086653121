@import './../../../variables.scss';
$translate : 27vw;
$translateDiv: 18vw;
#tecnologia-exclusiva{
  background-attachment: fixed;
  background-image: url("./../../../images/grupo/bg-tecnologia.jpg")!important;
  background-position: center;
  background-size: cover
}

.mobile-tecnologia{
  .slick-arrow, .slick-arrow:focus, .slick-arrow:hover{
    background-color:transparent;
  }
  .slick-arrow{
    background-size: contain;
  }
  .slick-arrow.slick-next {
    background-image: url("./../../../images/grupo/Flecha_2.png");
    opacity: 1;
    @media(min-width: $sizeMd){
      width: 2rem;
    }
  }

  .slick-arrow.slick-prev {
    background-image: url("./../../../images/grupo/Flecha_1.png");
    opacity: 1;
    @media(min-width: $sizeMd){
      width: 2rem;
    }
  }

  .slick-slide img{
    width: 25vw;
    @include mobile{
      width: 80%;
    }

    @media(min-width: $sizeLg){
      width: 20vw;
    }

    @media(min-width: 3000px){
      width: 10vw!important;
    }
  }
}


// Cards Carousel
// ----------------------------------------------
.cards-carousel{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon-cards {
  position: relative;
  width: 60vw;
  height: 40vw;
  max-width: 380px;
  margin: 0;
  color: white;
  perspective: 100vw;
  transform-origin: center;

  // This is the element that rotates with the animation

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    left: 0;
    right: 0;
    transform: translateZ(0) rotateY(0);
    animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;
    animation-play-state: paused;

    &.cards-position{
      &-left{
        animation-direction: reverse;
        animation-play-state: running;
      }
      &-right{
        animation-direction: unset;
        animation-play-state: running;
      }
    }
  }

  //// Individual cards

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 60vw;
    height: $translate*1.5;
    max-width: 380px;
    //max-height: 250px;
    transform-origin: center;

    &:nth-child(1) {
      transform: rotateY(0) translateZ($translate);
    }

    &:nth-child(2) {
      transform: rotateY(72deg) translateZ($translate);
    }


    &:nth-child(4) {
      transform: rotateY(144deg) translateZ($translate);
    }

    &:nth-child(5) {
      transform: rotateY(216deg) translateZ($translate);
    }

    img{
      max-width: $translateDiv;
    }
  }
}

// Carousel animation

@keyframes carousel {
  0%,  20%  { transform: translateZ(-$translate) rotateY(0); }
  30%,  40%  { transform: translateZ(-$translate) rotateY(-72deg); }
  50%,  60%  { transform: translateZ(-$translate) rotateY(-144deg); }
  70%,  80%  { transform: translateZ(-$translate) rotateY(-216deg); }
  90%,  100%  { transform: translateZ(-$translate) rotateY(-360deg); }
}
