@import './../../../variables.scss';
$border-radius: 0.7rem;

.agencia-servicios{
  cursor: pointer;
  .grid{
    display: grid;
    gap: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;

    @include mobile{
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }

    @include tablet{
      grid-gap: 0.5rem;
    }

  }

  .servicio{
    .flip-card,
    .flip-card-inner,
    .flip-card-back,
    .flip-card-front{
      border-radius: $border-radius!important;
    }
    h5{
      font-weight: 600;
      color: #fcc023;

      @include mobile{
        font-size: 1rem;
        margin-bottom: 0.2rem;
      }
    }

    .servicio-front,
    .servicio-hover{
      display:flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      border-radius: $border-radius!important;
    }

    .servicio-front{
      justify-content: center;
      align-items: center;
      visibility: visible;
      background-size:cover;

      .icon-profesionalismo,
      .icon-experiencia,
      .icon-solucion,
      .icon-servicios{
        height: 6rem;
        width: 6rem;
        display:block;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &.icon-profesionalismo{
          background-image: url("./../../../images/agencia/agencia-profesionalismo.png");
        }

        &.icon-experiencia{
          background-image: url("./../../../images/agencia/agencia-experiencia.png");
        }

        &.icon-solucion{
          background-image: url("./../../../images/agencia/agencia-solucion.png");
        }

        &.icon-servicios{
          background-image: url("./../../../images/agencia/agencia-servicios.png");
        }
      }


      h5{
        height: 3rem;
        text-align: center;

        @include tablet{
          @media(orientation: portrait){
            font-size: 1rem;
          }
        }
      }
    }
    .servicio-hover{
      border-radius: $border-radius!important;
      background-color: gray;
      position:relative;
      left: 0;
      bottom:0;
      right: 0;
      top:0;
      display: block;
      flex-direction: column;
      align-items: center;
      visibility: hidden;

      .half-top,
      .half-bottom{
        height: 45%;
        display:block;
        width:100%;
        padding: 1.4rem 0.5rem;
      }

      .half-top{
        text-align: center;
        padding: 1.4rem 0.5rem 0 0.5rem;
        @include mobile{
          height: 40%;
        }

        @include tablet{
          height: 40%;
          padding: 0.5rem 0.5rem 0 0.5rem;
        }
        img {
          max-height: 4.5vw;
        }

        h5{
          margin: 0.5rem 0;
          color: #b4b4b4;
          @include tablet{
            margin:0;
            font-size: 0.8rem;
            line-height: 1;
          }
        }

        .icon-profesionalismo,
        .icon-experiencia,
        .icon-solucion,
        .icon-servicios{
          height: 4.5rem;
          width: 4.5rem;
          display:block;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin: 0.5rem auto;

          @include mobile{
            height: 2.5rem;
            width: 2.5rem;
          }
          @include tablet{
            @media(orientation: landscape){
              height: 3.5rem;
              width: 3.5rem;
            }

            @media(orientation: portrait){
              height: 3rem;
              width: 3rem;
            }
          }
          &.icon-profesionalismo{
            background-image: url("./../../../images/agencia/agencia-profesionalismo-hover.png");
          }

          &.icon-experiencia{
            background-image: url("./../../../images/agencia/agencia-experiencia-hover.png");
          }

          &.icon-solucion{
            background-image: url("./../../../images/agencia/agencia-solucion-hover.png");
          }

          &.icon-servicios{
            background-image: url("./../../../images/agencia/agencia-servicios-hover.png");
          }
        }
      }

      .half-bottom{
        padding: 1rem 2.4rem 1.4rem 2.4rem;

        @media(max-width: $sizeMd){
          padding: 1rem 1rem 1.4rem 1.4rem;
        }

        @include mobile{
          height: 48%;
        }

        @include tablet{
          height: 65%;
          padding: 0.8rem;

          @media(max-width: 810px){
            padding: 0.3rem;
            margin-top: 1rem;
          }

          @media(orientation: portrait){
            p{
              line-height: 1!important;
              font-size: 0.3rem!important;
            }
          }

          @media(orientation: landscape){
            p{
              line-height: 1!important;
              font-size: 0.7rem!important;
            }
          }
        }
      }

      .half-bottom p{
        text-align: justify;
        font-size: 0.9rem;
        color: #ffffff;
        line-height: 1.2;

        @include mobile{
          margin-top: 0.2rem;
          font-size: 0.76rem;

          @media(max-width: 384px){
            font-size: 0.6rem;
          }
        }
      }
    }

    &:hover{
      .servicio-front{
        visibility: hidden;
        display:none;
      }
      .servicio-hover{
        visibility: visible;
      }
    }
  }
}
