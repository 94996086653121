@import './../../../variables.scss';

.certificaciones{
  @media(max-width: $sizeSm) {
    p {
      line-height: 1.3;
      font-size: 0.93rem;
      text-align: justify;
    }
  }

  .cert-item {
    align-items: center!important;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;

    img {
      @media(max-width: $sizeSm){
        margin-bottom: 1.5rem;
      }
    }

    &:nth-child(1) {
      img {
        max-height: 9.375rem;

        @media(max-width: $sizeSm){
          max-height: 6.25rem;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3){
      img {
        @media(max-width: $sizeSm){
          max-width: 18.2rem;
          width: -webkit-fill-available;
        }
      }
    }

    &:nth-child(4) {
      img {
        max-height: 10.62rem;
        @media(max-width: $sizeSm) {
          height: 8rem !important;
        }
      }
    }
    &:not(:hover) {
      .img-fluid {
        filter: grayscale(100%);
      }
    }
  }
}
