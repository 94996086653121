.vertical-line{
  position: relative;
}

.vertical-line:before {
  width: 2px;
  content: " ";
  display: block;
  height: 100%;
  position: absolute;
  left: calc(50% - 1px);
  right: 0;
  background-color: #000;
  z-index: 0;
}

.vertical-block {
  background-color: #fff;
  z-index: 1;
  display: block;
  position: relative;
}