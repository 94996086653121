@import './../../../variables.scss';

.cotizacion {
  text-align: justify;
  @media(min-width: $sizeSm) {
    h2 {
      color: #fcbf24;
    }
  }
  $border-radius: 0.8rem;

  .operation {
    position: relative;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: $border-radius;
    background-color: black;
    color: #ffffff;
    font-size: 1.8rem;
    padding: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: flex-end;

    @media(max-width: $sizeMd){
      font-size: 1.5rem;
    }

    > div {
      z-index: 2;
    }

    .compare {
      color: #fcbf24;
    }

    .number {
      color: #000;
      background-color: #fff;
      display: block;
      border-radius: $border-radius;
      padding: 0.1rem 0.2rem;
      font-size: 1.4rem!important;
      @media(max-width: $sizeSm){
        font-size: 1.2rem!important;
      }

    }

    &:before {
      content: " ";
      background-color: rgba(0, 0, 0, 0.4);
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: $border-radius;
      visibility: hidden;
      transition: visibility 50ms;
    }

    &:hover, &.active {
      &:before {
        visibility: visible;
      }
    }
  }


  @media(max-width: $sizeMd){
    .cotizacion-descripcion{
      background-color: #e5e5e5;
    }
  }

  .form-block {
    padding: 1rem 1rem 2rem 1rem;

    h3{
      margin-bottom: 1rem;
      text-align: center;

      @media(min-width: $sizeSm){
        font-size: 2rem;
        text-align: left;
        display:block;
        margin-bottom: 2rem;
      }
    }

    @media(min-width: $sizeSm){
      border: 1px solid #676768;
      background-color: #ffffff;
    }
  }

  .icon{
    background-repeat: no-repeat;
    padding-left: 3rem;
    background-size: 1.8rem;
    background-position: 0 0.5rem;

    @include tablet{
      @media(orientation: portrait){
        background-position: 0.5rem 0.5rem;
      }
    }
    &.at-email{
      background-image: url("../../../images/cotizacion/email-cotizacion.png");
    }

    &.phone-call{
      background-image: url("../../../images/cotizacion/whatsapp-cotizacion.png");
    }

    &.home-location{
      background-image: url("../../../images/cotizacion/horario-cotizacion.png");
    }

  }
}
