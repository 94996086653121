@import './../../../variables.scss';

$clients: "coparmex", "caaarem", "index", "canacintra", "dimbc";

#afiliaciones{
  h2{
    font-size: 1.7rem;
  }

  .slick-list{
    width: 70vw;
    margin: 0 auto;

    @include tablet{
      width: 80vw;
      margin: 0 auto;
    }
  }
}
#listado-afiliaciones{

  .afiliacion-item{
    display: block;
    width: 100%;
    height: 9vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 1rem;

    @include tablet{
      height: 12vw;
    }

    @media(max-width: $sizeSm){
      height: 20vw;
    }

  }
  @each $client in $clients {
    .#{$client} {
      cursor: pointer;
      background-image: url("./../../../images/grupo/afiliaciones/#{$client}.png");
      transition: background 200ms;
      &:hover{
        background-image: url("./../../../images/grupo/afiliaciones/#{$client}-active.png");
      }
    }
  }

}

#instituciones-afiliadas{
  .institucion-item{
    display: inline-block;
    width: 15vw;
    height: 6vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 1rem;
    @media(max-width: $sizeSm){
      width: 30vw;
      height: 10vw;
    }
    &.hospital-californias{
      background-image: url("./../../../images/grupo/instituciones/hospital.png");

      &:hover{
        background-image: url("./../../../images/grupo/instituciones/hospital-active.png");
      }
    }

    &.opera-en-la-calle{
      background-image: url("./../../../images/grupo/instituciones/opera.png");

      &:hover{
        background-image: url("./../../../images/grupo/instituciones/opera-active.png");
      }
    }
  }
}