$yellow: "#fcbf24";

$sizeXxs: 320px;
$sizeXs: 576px;
$sizeSm: 768px;
$sizeMd: 992px;
$sizeLg: 1200px;
$sizeXl: 1400px;

@mixin mobile {
  @media (max-width: $sizeSm - 1px){
    @content;
  }
}

@mixin tablet {
  @media (min-width: $sizeSm) and (max-width: $sizeLg) {
    @content;
  }
}

@mixin ipadAir {
  @media (min-width: 810px) and (max-width: 900px), (min-width: 1180px) and (max-width: $sizeLg){
    @content;
  }
}
