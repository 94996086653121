@import './../../variables.scss';

.servicios {
    h2, h3{
        @media(max-width: $sizeSm - 1px){
            font-size: 1.525rem !important;
        }
    }

    .service-info{
        //flex-direction: unset;

        @media(max-width: $sizeSm - 1px){
            flex-direction: column-reverse!important;

            .img-container{
                margin: 0 auto;
            }
        }

        @media(min-width: $sizeSm){
            flex-direction: unset;
            &:nth-of-type(2),
            &:nth-of-type(4){
                flex-direction: row-reverse;
            }
        }
    }
}

.servicios p{
    font-size: 1.25rem;
    text-align: justify;

    @media(max-width: $sizeSm - 1px){
        line-height: 1.3;
        font-size: 0.93rem;
        text-align: justify;
    }

}

.service-info .description{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.service-info .btn-filled{
    margin-bottom: 1.563rem;
}

.service-info.parent-hover:hover img{
    filter: grayscale(100%);
}
.service-info.parent-hover .img-container{
    position: relative;
}
.service-info.parent-hover .img-container:before{
    content: "";
    height: 2.188rem;
    width: 0.938rem;
    display:block;
    position:absolute;
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
    top: calc(50% - 1.063rem);
    transition: background 500ms;
}

.service-info.parent-hover:hover .img-container.arrow-left:before{
    left: -0.938rem;
    background-image: url('./../../images/icons/arrow-hover-right.png');
}

.service-info.parent-hover:hover .img-container.arrow-right:before{
    right: -0.938rem;
    background-image: url('./../../images/icons/arrow-hover-left.png');
}

.btn-bg-lightgray{
    background-color: #fff;
    color: #757473;
    border-color: #757473;
}

.btn-bg-lightgray:not(:hover):after{
    background-image: url("./../../images/icons/filled-btn-arrow-light-gray.png")!important;
}

.service-info.parent-hover:hover .btn-bg-lightgray:after{
    background-image: url("./../../images/icons/filled-btn-arrow.png")!important;
}