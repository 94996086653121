.plataforma-tecnologica{
  h2:after{
    width: 3px;
    content: " ";
    display: block;
    height: 3rem;
    position: absolute;
    left: calc(50% - 1px);
    right: 0;
    background-color: #f9b53f;
    z-index: 0;
  }
  .plataforma-box{
    cursor:pointer;
    background-color: #262626;
    color: #ffffff;
    padding: 1.5rem;
    display:block;
    border-radius: 2rem;
    min-height: 100%;

    .top-box{
      height: 60%;
      display:block;

      .input-blank{
        background-color: white;
        padding: 0.2rem 0.8rem;
        color: #acacac;
        font-weight: 500;
        font-size: 0.9rem;
      }
        .inbox{
          display:inline-block;
          margin: 0 0.7rem;
          background-color: #454444;
          border-radius: 0.3rem;
          padding: 0.2rem 0.6rem;
          line-height: 1.7rem;
          font-weight: 600;
          font-size: 1.2rem;
          height: 1.8rem;
          background-position: center;
          background-repeat:no-repeat;
          background-size: 2rem;
          color: transparent;
          width: 3.2rem;
          &.blank{
            background-image: url('./../../../images/agencia/21.png');
            margin:0;
          }
          &.viewed{
            background-image: url('./../../../images/agencia/11.png');
          }
          &.time{
            background-image: url('./../../../images/agencia/17.png');
            margin:0;
          }

        }
      .letter-spacing{
        font-size: 0.9rem;
        letter-spacing: 0.1rem;
        magin:0;
      }
      .input-btn,
      .input-btn-round{
        color: #ffffff;
        background-color: #f9b53f;
        padding: 0.3rem 0.5rem;
        text-align: center;
        font-weight: 600;

        &.input-btn-round{
          display:block!important;
          width: 50%;
          margin: 0 auto;
          padding: 0.25rem 0.6rem;
          border-radius: 0.3rem;
          font-size: 1.2rem;
          height: 1.8rem;
          color: transparent;
          background-size: 3.5rem;
          background-image: url('./../../../images/agencia/inicio.png');
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .bottom-box{
      height: 30%;
      display:block;
    }

    p{
      margin:0;
      padding:0;
      font-size: 0.82rem;
    }
  }
}
