@import './../../../variables.scss';
.acceso{
  header{
    .container{
      @include tablet{
        @media(orientation: landscape){
          max-width: 80vw !important;

        }

        @media(orientation: portrait){
          max-width: 90vw !important;

        }
      }
    }
  }

  .btn-access{
    @include tablet{


      @media(orientation: landscape){
        font-size: 1.2rem!important;

      }

      @media(orientation: portrait){
        font-size: 1rem!important;
      }
    }
  }
}
.acceso-portal{
  h1 {
    font-family: Gotham, Arial, sans-serif;
    font-size: 2rem;
    color: #fff;
    font-weight: 600;

    @include mobile{
      font-size: 1.6rem;
    }

    @include tablet{
      font-size: 1.6rem;
    }

    span{
      color: #fcbf24;
      font-weight: 400;
    }
  }
}
