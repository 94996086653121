@import './../../../variables.scss';
$buttons: 'outlook', 'whatsapp', 'formulario';

#solicita-cotizacion{

  .container{

    @include mobile{
      min-width: 100vw;
    }

    @include tablet{
      max-width: 95vw!important;

      @include tablet{
        & > .row{
          @media(orientation: landscape){
            padding: 3rem 0;
            margin-bottom: -3rem;
          }
        }
      }
    }
  }

  img{
    @media(max-width: $sizeLg){
      bottom: 0;
      position: absolute;
      right: 0;
      max-height: 35vw;
      width: auto!important;
    }

    @media(min-width: $sizeMd){
      margin-top: -2rem;
    }
  }

  //@include tablet{
  //  h2{
  //    font-size: 1.8rem;
  //  }
  //
  //  p{
  //    font-size: 0.88rem;
  //  }
  //}
  //

  @include tablet{

    @media (orientation: portrait){
        h2{
          font-size: 1.8rem;
        }

        p{
          padding:0;
          font-size: 0.8rem;
        }
    }
  }

  @media(max-width: $sizeSm - 1px){
    h2{
      font-size: 1rem;
    }

    p{
      padding:0;
      font-size: 0.5rem;
    }
  }

  .btn-rounded{
    border-radius: 3rem;
    background-size: 100%;
    display:block;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    color: #ffffff!important;
    vertical-align: middle!important;
    text-decoration: none!important;
    font-weight: 600;
    position: relative;
    font-size: 1rem;
    cursor:pointer;

    @media(max-width: $sizeSm){
      min-width: 8rem;
      padding: 0.5rem 0.3rem 0.5rem 2rem;
      margin-bottom: 0.3rem;
      font-size: 0.5rem;
    }

    @include tablet{

      @media (orientation: landscape){
        min-width: 14rem;
        padding: 0.6rem 0.3rem 0.6rem 2.7rem;
        margin-bottom: 0.3rem;
        font-size: 1rem;
      }

      @media (orientation: portrait){
        min-width: 12rem;
        padding: 0.6rem 0.3rem 0.6rem 2.5rem;
        margin-bottom: 0.3rem;
        font-size: 0.8rem;

        @include ipadAir{
          min-width: 11rem;
          font-size: 0.7rem;
        }
      }

    }

    @media(min-width: $sizeLg){
      min-width: 15rem;
      padding: 0.8rem 0rem 0.8rem 3.8rem;
      margin-bottom: 0.5rem;
    }

    &.formulario{
      background-color: #e9521b;
      &:hover{
        background: linear-gradient(180deg, #e9521b 50%, #f47b2a 50%);
      }

      &:after{
        background-image: url("./../../../images/clientes/logo_formulario.png");
      }
    }

    &.outlook{
      background-color: #2798d4;
      &:hover{
        background: linear-gradient(180deg, #2798d4 50%, #36bee4 50%);
      }

      &:after{
        background-image: url("./../../../images/clientes/logo_mail.png");
        @media(min-width: $sizeLg){
          background-size: 2.1rem;
          background-position: 1rem center;
        }
      }
    }


    &.whatsapp{
      background-color: #51b263;
      &:hover{
        background: linear-gradient(180deg, #51b263 50%, #6ed087 50%);
      }

      &:after{
        background-image: url("./../../../images/clientes/logo_whatsapp.png");

        @media(min-width: $sizeLg){
          background-size: 2.1rem;
          background-position: 1rem center;
        }
      }
    }

    &:after{
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-position: 0.8rem center;
      background-repeat: no-repeat;
      left: 0;
      top: 0;

      @media(max-width: $sizeMd){
        background-size: 1rem;
      }

      @media(min-width: $sizeMd) and (max-width: $sizeLg){
        background-size: 1.5rem;
      }
      @media(min-width: $sizeLg){
        background-size: 2.3rem;
      }
    }

  }
}
