@import './../../variables.scss';

.contact-box {
  .flex-column{
    background-color: #76706c;
    color: #fff;
    @media(min-width: $sizeXl){
      border-radius: 0 0.625rem 0.625rem 0;
    }

    @media(max-width: $sizeMd){
      border-radius: 0 0 0.625rem 0.625rem;
    }

    @media(min-width: $sizeMd) and (max-width: $sizeXl){
    p,input{
        font-size: 96%;
      }

      .mb-3{
        margin-bottom: 0.8rem!important;
      }

      h3{
        font-size: 1.575rem !important;
      }
    }
  }
}

.contact-box .form-control {
  background-color: #c4c2c0;
  color: #fff;
  border: none;
  box-shadow: 0.063rem 0.125rem 0.125rem -0.063rem rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0.063rem 0.125rem 0.125rem -0.063rem rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0.063rem 0.125rem 0.125rem -0.063rem rgba(0, 0, 0, 0.75);
  border-radius: 0.125rem!important;
  padding: 0.625rem;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}

.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}

.form-control:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}
.contact-box .image{
  padding:0;

  &:after{
    content: " ";
    height: 100%;
    width: 100%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100%;
    background-position: center;
    background-image: url("/images/inicio/atencion-personalizada-principal.jpeg");
    @media(max-width: $sizeMd) {
      background-image: url("/images/inicio/atencion-personalizada-principal-mobile.png");
      background-size: cover;
      border-radius: 0;
      height: 20rem;
      background-position: top center;
    }
  }
}

.cotiza-ahora {


  color: #ffffff;
  .contact-box{
    background-color: #76706C;
    border-radius: 0.313rem;
    padding:0;

    @media(min-width: $sizeSm){
      .owl-carousel .owl-stage-outer {
        border-radius: 0 0.313rem 0.313rem 0;
      }
    }


    @include tablet{
      @media(orientation: portrait){
        h3{
          font-size: 1.4rem!important;
        }

        p{
          font-size: 1rem;
          padding-bottom: 0!important;
        }

        .owl-carousel .owl-stage-outer{
          zoom: 1.4;
          margin: 0 auto;
        }
      }
    }
  }
  .options {
    font-size: 1.5rem;
    font-weight: 700;
    list-style: none;

    @media(max-width: $sizeSm){
      font-size: 0.98rem;
    }

    @include tablet{
      font-size: 1.15rem;
    }
  }
  @media(min-width: $sizeSm) {
    .text-yellow {
      color: #fff!important;
    }
  }

  .btn-filled {
    border-color: #fff;
    color: #fff;
    &.btn-yellow{
      background-color: #fcbf24!important;
      border-color: #fcbf24!important;
      color: #000;
    }
    &:hover {
      background-color: #fcbf24!important;
      border-color: #fcbf24!important;
      color: #000;
    }

    background-color: transparent;
  }

  .image {
    border-radius: 0 0.625rem 0.625rem 0;
  }

  .owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: #fff;
        }
      }
    }
  }

  .owl-dots {
    &:after {
      height: 100%;
      content: "";
      width: 100%;
      display: block;
      z-index: 100;
      position: absolute;
      top: 0;
    }
  }

}

.contact-box {
  h3 {
    font-size: 1.875rem !important;

    @media(max-width: $sizeXs) {
      font-size: 1.58rem !important;
      color: $yellow !important;
    }
  }

  p{
    @media(max-width: $sizeSm){
      font-size: 1rem;
      width: 80vw;
    }
  }

  .btn-filled {
    color: #ffffff;
    border-color: #ffffff;
    margin-bottom: 1.25rem;

    &:hover {
      border-color: #fcbf24;
      color: $yellow;
    }
  }
}

.main-header {
  &.grupo{
    background-position: center;
  }
  &.inicio{
    min-height: 54.188rem;
    background-position: 30%;

    @media(max-width: $sizeSm) {
      min-height: 38rem;
      justify-content: space-evenly;

    }

    @media(min-width: $sizeSm) and (max-width: $sizeMd) {
      min-height: 54.188rem;
      justify-content: space-evenly;

    }

    .header-container{
      min-height: 25vw;
      display: flex;
      align-content: center;
    }

    @include tablet{
      @media (orientation: landscape){
        min-height: 40.188rem;
      }

      @media (orientation: portrait){
        min-height: 33.188rem;

        @include ipadAir{
          min-height: 31.188rem;
        }

      }
    }
  }
}

.bg-gray,
.cotiza-ahora {
  background-color: #8f9196;
}

.service-block .btn-filled {
  border-color: #ffffff;
  color: #fff !important;
  margin-top: 0.625rem;
  width: max-content;
}

.service-block .btn-filled:hover {
  border-color: #fcbf24;
}

.service-block .btn-filled {
  font-size: 1.25rem;

  @media(max-width: $sizeSm) {
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
  }
}

.news {
  background-color: #ffffff;
  padding: 1.25rem 0 4.375rem 0 !important;
}

.news .preview h6,
.news .preview h5 {
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
}

.news .preview h5 {
  margin-bottom: 0.938rem;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news .owl-item.active:nth-child(1n + 2) .preview .details {
  border-left: 0.125rem solid #76706c;
}

.news .owl-item .preview .details {
  height: 8rem;

  @media(min-width: $sizeMd){
    height: 9rem;
  }

  @include tablet{
    height: 10rem;
  }
}

.news .owl-item .preview .details .bottom-box {
  position: absolute;
  bottom: 0;
}

.news .preview:hover h6 {
  background-color: #76706c;
  border-radius: 0.625rem 0.625rem 0 0;
  color: #ffffff;
}

.news .preview .publish-date {
  font-weight: 600;
  color: #fcbf24;
  margin-top: 0.313rem;
  display: block;
}

.news .preview h6 {
  padding: 0.625rem;
  margin: 0 !important;
  width: 100%;
}
.news .preview .thumbnail{
  width: 100%;
  background-size: cover;
  background-color: #8F9196;
  background-position: center;

  @include tablet{
    height: 10rem;

    @media(orientation: portrait){
      height: 15rem;

      @include ipadAir{
        height: 10rem;
      }
    }
  }

  @media(min-width: $sizeXl){
    height: 10rem;
  }
}

.news .preview .read-more {
  font-size: 0.95rem !important;
  font-weight: 500;
  text-decoration: none;

  &:hover{
    color: #fcbf24!important;

    &:after{
      background-image: url("./../../images/icons/filled-btn-arrow-yellow.png") !important;
    }
  }
}

.news .preview .read-more:after {
  content: "";
  width: 12px;
  height: 13px;
  font-size: 25px;
  margin-left: 5px;
  line-height: 0px;
  vertical-align: middle;
  background-size: contain;
  background-image: url("./../../images/icons/filled-btn-arrow-gray.png") !important;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.service-block {
  min-height: 43vw;
  background-repeat: no-repeat;
  background-size: 46vw auto;
  background-position: center;
  transition: background-position 400ms;
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;

  &:not(:hover){
    h3{
      text-shadow: 0.125rem 0.188rem 0.188rem #1c1e20;
      transition: text-shadow 500ms;
    }
  }
  h3 {
    color: #ffffff;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 0rem;
    transition: margin 400ms;

    @media (max-width: $sizeMd) {
      font-size: 1.2rem;
      line-height: 1;
    }
  }

  .btn-filled {
    transition: margin 400ms;
  }

  @media (max-width: $sizeMd) {
    min-height: 20vw;
    background-repeat: no-repeat;
    background-size: 20vw auto;
  }
}

@media (min-width: $sizeMd) {
  .service-block:hover {
    background-position: center -2rem;
  }

  .service-block:hover h3 {
    margin-top: -2rem;
  }
  .service-block:hover .btn-filled {
    margin-bottom: 2rem;
    color: #34312c !important;
    transition-property: background, bottom;
  }
}

#service-one,
#service-two,
#service-three {
  transition: background 200ms;

  @media(max-width: $sizeMd) {
    background-size: cover;
  }
}

#service-one {
  background-image: url("/images/inicio/img-1.jpg");

  &:hover {
    background-image: url("/images/inicio/img-1-hover.jpg");
  }
}

#service-two {
  background-image: url("/images/inicio/img-2.jpg");

  &:hover {
    background-image: url("/images/inicio/img-2-hover.jpg");
  }
}

#service-three {
  background-image: url("/images/inicio/img-3.jpg");

  &:hover {
    background-image: url("/images/inicio/img-3-hover.jpg");
  }
}

@media(max-width: $sizeMd) {
  .contact-box {
    border-radius: 0 !important;
  }

  .service-block {
    min-height: 275px;
  }
}

@media(min-width: $sizeSm) and (max-width: $sizeMd) {
  .service-block {
    min-height: 500px;
  }
}

.tus-socios {

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media(max-width: $sizeSm){
    opacity: 0;
    &.scroll{
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 800ms;
    }
  }
  @media(min-width: $sizeSm){
    height: 18.75rem;
    position: relative;

    h2 {
      font-size: 6.25rem;
      font-family: Gotham, Arial;
      width: 41.875rem;
      text-indent: 6.25rem;
      line-height: 6.25rem;
      margin: 0 auto;
      color: #fcbf24;
      opacity: 0;
      display: block;
      transition: opacity 1500ms ease-in-out;
    }

    &.scroll {
      h2 {
        opacity: 1;
      }

      &:before {
        width: calc(50% - 16.25rem);
      }

      &:after {
        width: calc(50% - 5.625rem);
      }
    }

    &:before {
      content: "";
      display: block;
      background-color: #fcbf24;
      width: 0;
      position: absolute;
      left: 0;
      top: 0.625rem;
      height: 5rem;
      transition: width 1000ms ease-in-out;
    }

    &:after {
      content: "";
      display: block;
      background-color: #fcbf24;
      width: 0;
      position: absolute;
      right: 0;
      height: 5rem;
      bottom: 0;
      transition: width 1200ms ease-in-out;
    }
  }
}
