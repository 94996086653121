@import './../../variables.scss';
header
header .container{
    @include tablet {
        max-width: 80vw!important;

        @include ipadAir{
            max-width: 100vw!important;
        }
        
        @media (orientation: portrait) {
            max-width: 90vw!important;
        }
    }

    @media(min-width: $sizeLg){
        max-width: 1140px!important;
    }
}

.navbar{
    transition: background-color 200ms, height 200ms;
}

.navbar .navbar-brand img{
    transition: height 200ms ease-in-out;
}

.navbar.scroll{
    position: fixed;
    width: 100%;
    top:0;
    z-index:9;
    background-color: rgba(0,0,0,0.6);
}

.navbar.scroll .navbar-brand img{
    height: 6.25rem;
}

.header-container{
    min-height: 16vw;
    @media(max-width: $sizeSm - 1){
        min-height: 30vw;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}
.header-container.scroll{
    position: relative;
    top: 235px;

    @include tablet{
        top: 10rem;
    }
}
.navbar-nav {
    align-items: center;
    z-index: 10;
    .nav-link {
        font-weight: 600;
        text-transform: initial;
        font-size: 1.15rem;
        color: #ffffff !important;
        &:hover {
            color: #fcbf24 !important;
        }

        @media(max-width: $sizeSm - 1px) {
            font-size: 0.78rem;
            margin-right: 0.8rem;
        }

        @include ipadAir{
            font-size: 1rem!important;
        }
    }
    .dropdown {
        span {
            font-weight: 600;
            text-transform: initial;
            font-size: 1.15rem;
            color: #ffffff !important;
        }
        a {
            font-weight: 600;
            text-transform: initial;
            font-size: 1.15rem;
            color: #ffffff !important;
        }
    }
    flex-direction: row;

    @media(min-width: $sizeSm){
        justify-content: flex-end;
    }
}

.bg-grayish{
    background-image: url("../../images/nav-bg.png");
}

.navbar-dark .navbar-toggler{
    color: transparent;
    border-color:transparent;
    cursor: pointer;
}

header .navbar-brand img{
    background-color: transparent!important;
    height: 8.25rem;
    padding-left:0!important;
    padding-right:0!important;
}
header .navbar:not(.scroll) .navbar-brand img{
    margin: 0.375rem auto;

    @include tablet{
        padding:0!important;
        max-height: 70px!important;
    }
}

header .navbar.scroll .navbar-brand img{
    padding:0!important;
    max-height: 70px!important;
}

header .navbar-brand{
    width: 22.8rem;
}


.navbar-nav .show>.nav-link,
.navbar-nav .dropdown a:hover{
    color: #fcbf24!important;
}

.navbar-nav .dropdown a:hover{
    background-color: transparent;
}

.dropdown-toggle::after{
    color: transparent;
}

.language{
    width: 2.188rem;
    height: 2.188rem;
    display:inline-block;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    vertical-align: bottom;
}

.language.lang-es{
    background-image: url('./../../images/language-es.png');
}

.language.lang-en{
    background-image: url('./../../images/language-en.png');
}

.acceso-glr{
    border: 0.063rem solid #fff;
    padding: 0.188rem 1.6rem;
    border-radius: 0.625rem;
    background-repeat: no-repeat;
    background-position: 0.625rem;

    @media(max-width: $sizeSm - 1px){
        font-size: 0.8rem;
        padding: 0.188rem 1rem;
    }

    @include tablet{
        font-size: 1rem;
        padding: 0.188rem 0.75rem;

        @include ipadAir{
            padding: 0.188rem 0.35rem;
        }
    }
}

.acceso-glr:hover{
    color: #000;
    background-color: #fcbf24;
    border-color: #fcbf24;
}

@media(max-width: 767px){
    header .navbar-brand img{
        height: 6.25rem;
    }
}

@media(max-width: $sizeSm - 1px){
    header .container{
        flex-direction: row-reverse;
    }

    header .navbar-brand{
        flex: auto;
    }

    .navbar-dark .navbar-brand{
        display:flex;
        justify-content: center;
    }

    .navbar-nav .dropdown-menu{
        background-color: transparent;
        border: none;
        position: absolute;
        top:40px;
        background-image: url("../../images/nav-bg.png");
    }
}

@media(min-width: $sizeSm){
    .navbar-expand-lg .navbar-collapse{
        align-content: flex-end;
    }

    .navbar-nav .nav-link:not(:last-child){
        margin-right: 1.25rem;
    }

    .navbar-nav .dropdown-menu{
        border: none;
        background-image: url("../../images/nav-bg.png");
    }

    .dropdown{
        display: flex;
        align-content: center;
        flex-direction: row;
    }
    .dropdown-toggle::after{
        display:none;
    }

}

.btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
}

.main-header {
    min-height: 40.625rem;
    position: relative;
    background-size: cover;

    .text-container {
        h4 {
            font-size: 2.8rem;
            font-weight: 600;
            color: #ffffff !important;
            text-shadow: 0.125rem 0.188rem 0.188rem #1c1e20;
            font-family: Gotham, Arial;
            line-height: inherit;

            @media(max-width: $sizeSm - 1px) {
                font-size: 1.8rem;
                line-height: 1.3;
            }

            @include tablet{
                font-size: 2rem;
            }

        }

        .btn-filled {
            font-size: 1.3rem;
            display: inline-block;

            &.always-active {
                background-color: #fcbf24 !important;
                border-color: #fcbf24 !important;
                color: black !important;
            }

            &:not(:hover) {
                color: black !important;

                &:not(.always-active) {
                    border-color: #ffffff;
                    background-color: transparent;
                    color: #ffffff !important;
                }

                @media(max-width: $sizeMd) {
                    background-color: #fcbf24;
                    border-color: #fcbf24;
                    color: black !important;
                }
            }
        }
    }

    @media(max-width: $sizeSm - 1px){
        min-height: 33.875rem;
    }

    @include tablet{
        min-height: 53.125rem;
        &:not(.inicio){
            min-height: 30.125rem;

            @media(max-width: 1023px){
                .header-container{
                    padding-top: 5rem!important;
                }
            }
        }
    }

    @media handheld and (orientation: landscape) and (max-width: $sizeLg){
        max-height: 30.125rem!important;

        h4{
            font-size: 2.2rem;
        }
    }

    @media(max-width: $sizeMd) {
        &.agencia {
            background-position: center;
        }
    }

}

.nav-extra{
    background-image: url("../../images/menu-icon.png");
    width: 1.875rem;
    height: 1.875rem;
    background-color: transparent;
    border:none;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.menu-toggle {
    position: absolute;
    width: 80vw;
    height: 0;
    overflow: hidden;
    transform: scaleY(0);
    transition: transform 400ms ease-in-out;
    z-index: 1000;

    @media(min-width: $sizeSm){
        width: 100%;
    }
    .nav-tabs {
        border: none;
        .nav-item.show {
            .nav-link {
                border: none !important;
            }
        }
        .nav-link.active {
            border: none !important;
        }
        .nav-link {
            &:active {
                border: none !important;
            }
            &:hover {
                border: none !important;
            }
        }
    }
    .nav-pills {

        .nav-link{
            font-size: 1.2rem;
            padding-top: 1rem;
            padding-bottom:1rem;
        }
        .nav-link.active {
            background-color: #2e2c2e;
            color: #fcbf24;
            border-radius: 0;
            @media(min-width: $sizeSm){
                background-color: #c9ccce;
                color: #fff;
            }
        }
        .show {
            >.nav-link {
                background-color: #c9ccce;
                color: #fff;
                border-radius: 0;
            }
        }
    }
    .nav-link {
        color: #c9ccce;
        border-radius: 0;
        font-size: 20px;
        font-weight: 500;

        @media(max-width: $sizeSm - 1px){
            background-color: #2e2c2e;
            color: #fff;
            font-size: 1rem;
        }
        @media(min-width: $sizeSm){
            margin-right: 25px;
            padding: 0px 8px;
        }
    }
    h6 {
        font-weight: 600;
        font-size: 18px;
        a{
            text-decoration: none;
        }
    }
    .menu-list {
        border-bottom: 1px solid #000;

        @media(min-width: $sizeSm - 1px){
            border-left: 1px solid #000;
            border-bottom: none;
            >div {
                padding: 0 1.25rem;

                @media(orientation: portrait){
                    padding: 0 0.85rem;
                }
            }
        }

        padding: 20px 10px;
        a, div, span, h6{
            color: #212529!important;
        }
        ul {
            list-style: none;
            padding: 0;

            ul {
                margin-left: 1.5rem;

                li:not(:last-child){
                    padding-bottom: 0.2rem!important;
                    margin-bottom: 0!important;
                }
            }

            li {
                a{
                    text-decoration: none;

                    &:hover{
                        color: #dfa209!important;
                        text-decoration: underline;
                    }
                }
                &:first-child {
                    @media(min-width: $sizeMd){
                        margin-top: 0.938rem;
                    }
                }
                &:not(:last-child) {
                    @media(min-width: $sizeMd) {
                        margin-bottom: 0.938rem;
                    }
                }
            }

            @media(min-width: $sizeMd){
                margin: 0 0 30px 0;
            }
        }
        &:hover {
            * {
                color: #545556!important;
            }
            background-color: #c9ccce !important;
            border-color: #c9ccce !important;


            .header-contact{
                li{
                    &.phone:before{
                        background-image: url("../../images/icons/menu-icon-tel-gray.png")!important;
                    }

                    &.email:before{
                        background-image: url("../../images/icons/menu-icon-email-gray.png")!important;;
                    }

                    &.location:before{
                        background-image: url("../../images/icons/menu-icon-direccion-gray.png")!important;;
                    }
                }
            }
        }
    }
}
.menu-toggle.toggled {
    background-color: #fff;
    transform: scaleY(1);
    transform-origin: top;
    border-bottom: 2px solid #000;
    @media(min-width: $sizeMd){
        height: 600px;
    }
    @media(max-width: $sizeMd){
        height: min-content!important;
    }
}
.menu-toggle.toggled.main-toggled {
    @media(min-width: $sizeMd){
        height: 44rem;
        width: 100vw;
    }
}
.menu-toggle.scroll {
    position: fixed;
    z-index: 100;
    top: 95px;

    @media(max-width: $sizeSm - 1px){
        top: 9.8rem;
        height: min-content!important;
    }
}


#rodriguez-navbar{
    @media(max-width: $sizeSm - 1px){
        display: flex;
        align-content: center!important;
    }
    .second-menu{
        @media(max-width: $sizeSm - 1px){
            position: absolute;
            right: 0.938rem;
        }
    }
}


@media(max-width: $sizeMd){
    .menu-toggle{
        font-size: 1.1rem;

        @include tablet{
            font-size: 0.9rem;
        }

        .menu-list{
            padding: 1rem;

            &:last-of-type{
                border-bottom: 0;
            }

            ul{
                margin:0;
            }
        }
        h6{
            font-size: 1.2rem;
        }

        ul li:not(:last-child) {
            margin-bottom: 0.5rem!important;
        }

        & > div{
            line-height: 1.2;
        }
    }
}

.bg-dark-gray{
    background-color:#514e4a;
}

.header-contact{
    font-size: 1rem;

    @media(max-width: $sizeMd){
        font-size: 0.9rem;
    }
    ul{
        margin-left: 1.5rem!important;

        ul{
            margin-left: 1.5rem!important;
        }
        li{
            margin-bottom: 0rem!important;
            @media(min-width: $sizeLg){
                margin-bottom: 0.4rem!important;
            }
            &.email{
                line-height: 1.2;
                a:hover,
                a:link,
                a:visited,
                a:active{
                    color: #2e5984!important;
                    text-decoration: none;
                }
            }
            &:before {
                content: "";
                margin-left: -1.5rem;
                margin-right: 0.6rem;
                display: inline-block;
                width: 1.3rem;
                height: 2rem;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.v-middle:before{
                vertical-align: middle;
            }

            &.v-unset:before{
                vertical-align: unset;
            }

            &.v-top:before{
                vertical-align: top;
            }

            &.phone:before{
                background-image: url("../../images/icons/menu-icon-tel.png");
            }

            &.email:before{
                background-image: url("../../images/icons/menu-icon-email.png");
            }

            &.location:before{
                background-image: url("../../images/icons/menu-icon-direccion.png");
            }
        }
    }
}


.collapsible-menu{
    &:after{
        content: " ";
        width: 10px;
        height: 10px;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 5px;
        background-image: url("./../../images/icons/arrow-collapsible.png")!important;

    }

    &.open:after{
        background-image: url("./../../images/icons/arrow-collapsible.png")!important;
        transform: rotate(180deg);

    }
}
