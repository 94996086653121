@import './../../../variables.scss';
#quienes-somos{
  text-align: justify;
  display:block;

  @media(min-width: $sizeSm){
    background-color: #e9f2f3;
  }

  @media(max-width: $sizeSm - 1px){
    .strip{
      background-color: #e5e5e5;

      img{
        max-width: 50vw;
      }
    }


    @include tablet{
      margin-bottom: 0!important;
    }
  }

  img{

    @include tablet{
      position:absolute;
      bottom:0;
      left:0;
      width: calc(100% - 0.8rem)!important;
      margin-top: -0.2rem!important;

      @include ipadAir{
        @media (orientation: portrait){
          width: 100% !important;
          left: 100%;
          bottom:0;
        }

        @media (orientation: landscape){
          width: 70% !important;
          margin: 0 auto;
        }

      }
    }

    @media (min-width: $sizeMd) and (max-width: $sizeLg){
      position:absolute;
      left: 0;
      right:0;
      width: 100%;
      bottom:0;
    }

    @media(min-width: $sizeLg){
      margin-top: -1rem;
    }
  }

  p{
    text-align: justify;

    @include ipadAir{
      font-size: 0.8rem;
    }
  }
}
