@import './../../../variables.scss';

.core-values {
  background-image: url('./../../../images/nosotros/core-values-02-bg.jpg');
  color: #000;

  &.freight-forwarding {
    background-image: url('./../../../images/nosotros/core-values-01-bg.jpg');
    color: #fff;
  }

  background-size: cover;
  background-position: center;
  font-size: 1.25rem;

  h2 {
    @media(max-width: $sizeSm) {
      font-size: 1.58rem !important;
    }
  }

  .icons {
    background-image: url('./../../../images/nosotros/dark-transparency.png');
  }

  h6 {
    font-size: 1.125rem;
  }

  .icon-value {
    height: 7.813rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 1.875rem;
  }
}
