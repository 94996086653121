.wa-mobile{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(255, 255, 255);
  width: 64px;
  height: 64px;
  border-radius: 100%;
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
  background-image: url("./../../images/icons/whatsapp-green.png") !important;
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 999999;

  span:before{
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgb(255, 0, 0);
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    right: 4px;
    top: 4px;
    display: block !important;
  }
}