@import './../../../variables.scss';

.freight-forwarding {
  .quienes-somos {
    background-image: url("./../../../images/freight/quienes-somos-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;

    @media (max-width: $sizeMd){
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 1) 60%), url("./../../../images/freight/quienes-somos-bg.jpg");
    }

    @include mobile{
      h2{
        font-size: 1.5rem;
      }
      p{
        font-size: 0.8rem;
      }
    }

    //@include tablet{
    //  background-position: right bottom;
    //}

    //@media(min-width: $sizeLg){
    //  background-position: 4rem bottom;
    //}
  }

  .quienes-somos-counters{

    .valores{
      background-color: unset;
      color: unset;
      padding: 0!important;
      h5{
        font-weight: 600;
        font-size: 1.1rem;
        min-height: 2.5rem;
      }

      p{
        font-size: 1rem;
      }
    }
    .contador{
      text-align: center;
      h6{
        color: #fcc023;
        font-weight: 600;
        font-size: 3.5rem;
        margin-bottom: 0;
      }

      p{
        text-transform: uppercase;
        font-weight: 300!important;
        color: #b7b6b6;
        font-size: 1rem;
      }
    }

    .divisor-vertical-gray{
      &:after{
        content: " ";
        min-height: 3.438rem;
        background-size: 1.563rem;
        background-position: right center;
        background-repeat: no-repeat;
        width: 0.063rem;
        position: absolute;
        top: 11vw;
      }
    }
  }

  .freight-fortalezas {
    ul {
      margin-top: 1rem;
      -moz-column-count: 2;
      -moz-column-gap: 2rem;
      -webkit-column-count: 2;
      -webkit-column-gap: 2rem;
      column-count: 2;
      column-gap: 2rem;

      @media(min-width: $sizeLg){
        column-count: 2;
        column-gap: 7rem;
      }

      @include mobile {
        column-count: 1;
      }
    }
  }

  .info-adicional {
    ul {
      margin-top: 1rem !important;
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;

      li {
        margin-bottom: 1rem;

        span {
          height: 4rem;
          display: inline-block;
          line-height: 1.3rem;
          vertical-align: text-top;

          @include mobile {
            height: auto;
          }
        }

        @include mobile {
          margin-bottom: 0.3rem;
        }
      }

      @include mobile {
        column-count: 1;
      }
    }
  }

  .soluciones {
    h2 {
      padding-bottom: 2rem;
      @include mobile {
        font-size: 1.7rem;
        padding-bottom: 1rem;
      }
    }

    text-align: center;

    img {
      margin-bottom: 1rem;
      max-height: 7rem;

      @include mobile {
        max-height: 5.8rem;
      }
    }

    p {
      color: #a7a9ab;
      @include mobile {
        font-size: 1rem;
      }
    }
  }
}
.valores.freight-forwarding{
  h6{
    font-size: 1.1rem!important;
  }
}
