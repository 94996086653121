@import './../../../variables.scss';

#new-header{
  background-size: 65vw;

  @include tablet {
    background-size: contain;
  }

  background-position: right top;
  .header-image{
    background: linear-gradient(45deg, #e4e2e2 60%, transparent 40%);
    @media(min-width: $sizeLg){
      background: linear-gradient(45deg, #e4e2e2 50%, transparent 50%);
    }
  }

  strong, p{
    font-size: 0.8rem;

    @include tablet {
      font-size: 1rem;
    }
  }


  @include mobile{
    h2{
      font-size: 1rem;
    }
  }
  @include tablet{
    h2{
      font-size: 1.4rem;
    }
  }

  @media(min-width: $sizeMd){
    strong, p{
      font-size: 1rem;
    }
  }
}

.new-source{
  color: #0061a5!important;
  font-weight: 600;
  text-decoration: none;

  @media(max-width: $sizeMd){
    font-size: 1.1rem;
  }

}

.new-body{
  text-align: justify;

  @media(min-width: $sizeMd){
    column-count: 2;
    column-gap: 5rem;
  }


    h1,h2,h3,h4,h5,h6{
      font-size: 1.4rem;
      font-family: Gotham, Arial, sans-serif;
      font-weight: 600;
      color: #000000!important;
      padding-bottom: 1.2rem;
      @include mobile{
        font-size: 1.2rem;
        padding-bottom: 1rem;
      }

      @include tablet{
        h1,h2,h3,h4,h5,h6{
          font-size: 1.2rem;
        }
      }
    }
}
