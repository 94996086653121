@import './../../../variables.scss';
.negocio-facil{
  .images{
    img {
      width: 60%;
      @media(min-width: $sizeLg){
        width: 48%;
        margin-left: 8rem!important;
      }

      @media(max-width: $sizeSm){
        width: 80%;
      }
    }

  }
  .trailer{
    @include mobile{
      img{
        position: absolute;
        max-width: 80vw;
        left: -5vw;
      }
    }

    @media(min-width: $sizeLg){
      position: absolute;
      top: -8vw;
      margin-bottom: -10vw;

      img{
        position: absolute;
        max-width:40vw;
      }
    }
  }
}
.quienes-somos-main{
  .lugares{
    h3{
      font-size: 1.4rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      @media(max-width: 800px){
        font-size: 1rem;
        text-align: center;
      }
    }

    .divisor-vertical-yellow{
      @media(max-width: 800px){
        margin:0rem!important;
        padding: 0 0.5rem 0 0;
      }
      &:after{
        top: calc(50% - 1.4rem);
        min-height: 2.4rem!important;
      }
    }
  }
}
.quienes-somos-transport{
  background-color: #eeeeee;
  @include mobile{
    hr{
      color: #fcc023!important;
      height: 0.1rem;
    }
  }
  .divisor-vertical-yellow{
    &:after{
      top: calc(50% - 2rem);
    }
  }
  .contador{
    text-align: center;
    h6{
      color: #fcc023;
      font-weight: 600;
      font-size: 3.5rem;
      margin-bottom: 0;

      span{
        font-weight: 500;
        color: #7f7b7c;
      }
    }
    p{
      font-weight: 600;
      font-size: 0.8rem;
      color: #7f7b7c;
    }
  }
}

.trailer-section{
  background: linear-gradient(180deg, #FFFDFD 20%, #eeeeee 20%);

  @include mobile{
    h3{
      font-size: 1.2rem;
    }
    p{
      font-size: 0.8rem;
    }
  }
  .btn-filled{
    background-color: #7f7b7c;
    border:none;
    color: #ffffff!important;
    max-width: max-content;
    border-radius: 4rem;
    &:after{
      background-image: url("./../../../images/icons/filled-btn-arrow-gray.png")!important;
    }
  }

  @include tablet{
    @media(orientation: landscape){
      background: linear-gradient(180deg, #FFFDFD 30%, #eeeeee 30%);

      @media(min-width: 769px){
        background: linear-gradient(180deg, #FFFDFD 27%, #eeeeee 27%);
      }
    }
  }
}
