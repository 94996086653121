@import './../../variables.scss';

.contacto{
  .main-header{
    .header-container{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;

      @media(min-width: $sizeMd) and (max-width: $sizeMd){
        align-content: stretch;
        align-items: flex-end;
      }
      @media(min-width: $sizeLg){
        align-items: stretch;
        align-content: stretch;
      }
    }
  }
  h4{
    font-weight: 600;
    font-size: 1.8rem;
  }

  .text-medium{
    font-size: 1.65rem;
  }
}

.empleo{
  .main-header{

      .header-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include mobile{
          justify-content: center;
        }

        @media(min-width: $sizeMd) and (max-width: $sizeMd){
          align-content: stretch;
          align-items: flex-end;
        }
        @media(min-width: $sizeLg){
          align-items: stretch;
          align-content: stretch;
        }

        @include tablet{
          @media(orientation: landscape){
            @include ipadAir{
              width: 80vw;
              padding-top: 0!important;
            }
          }

        }
      }

    .text-container h4{
      line-height: 1.2;
      font-size: 3rem;

      @include mobile{
        font-size: 1.8rem;
        line-height: 1.3;
      }
      @include tablet{
        line-height: 1.2;
        font-size: 2rem;

        @media(orientation: landscape){
          font-size: 2.4rem;
        }
      }
    }
  }
  h4{
    font-weight: 600;
    font-size: 1.8rem;
  }

  .text-medium{
    font-size: 1.65rem;
  }
}
