@import './../../../variables.scss';

.grupo-solucion{
  text-align: center;
  position: relative;
  p{
    min-height: 5rem;

    @media(max-width: $sizeSm){
      font-size: 1rem;
      line-height: 1;
      min-height: 1.8rem;
    }

    @include tablet{
      font-size: 0.8rem;
      line-height: 1.2;
      min-height: 3rem;
    }
  }
  img{
    margin-bottom: 0.5rem;

    @media(max-width: $sizeLg - 1px){
      width: auto;
      height: 12vw;
    }

    @include tablet(){
      @media(orientation: landscape){
        width: auto;
        height: 11vw;
      }
    }
    @media(min-width: $sizeLg){
      width: auto;
      max-height: 20vw;
    }
  }
}

.ventaja-competitiva{
  img{
    @media(max-width: $sizeSm - 1px){
      max-width: 30vw;
    }

    @media(max-width: $sizeMd){
      height: 16vw;
    }
    @media(min-width: $sizeMd){
      max-height: 8vw;
    }
    width: auto!important;
  }
}
