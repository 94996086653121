@import './../../../variables.scss';

/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;

  @media(min-width: $sizeMd){
    max-width: 45rem;

  }
}
#conference-timeline .timeline-start,
#conference-timeline .timeline-end {
  display: table;
  font-size: 2rem;
  line-height: 1.2;
  background: #fff;
  max-width: auto;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;

  @media(min-width: $sizeMd){
    h2{
      margin-bottom:0;
      font-size: 1.8rem;
    }
    p{
      font-size: 1.5rem;
      line-height: 1;
    }
  }
}
#conference-timeline .conference-center-line {
  position: absolute;
  width: 3px;
  height: calc(100% - 8rem);
  top: 0;
  left: 50%;
  margin-left: -2px;
  background: #1d1d1b;
  z-index: -1;

  @include tablet{
    @media (orientation: portrait) {
      height: calc(100% - 4.5rem);
    }

    @media (orientation: landscape) {
      height: calc(100% - 5rem);
    }

  }
}
#conference-timeline .conference-timeline-content {
  padding-top: 3.75rem;
}
.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {
  max-width: 100%;
  width: 100%;
  &.content-left-container{
    padding: 2rem 3rem 2rem 2rem;

    @include tablet{
      padding: 0rem 3rem 0rem 0rem;
    }
  }

  &.content-right-container{
    padding: 2rem 0rem 2rem 3rem;

    @include tablet{
      padding: 0rem 0rem 0rem 3rem;

    }

  }
}
.timeline-article .timeline-author {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242424;
  text-align: right;
}
.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
}
.timeline-article p {
  padding: 0;
  font-weight: 400;
  color: #242424;
  font-size: 1rem;
  line-height: 1.2;
  position: relative;

  @media(max-width: $sizeMd){
    font-size: 0.87rem;
  }
}
.timeline-article .article-number {
  position: unset;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1;
  color: #1d1d1b;

  @media(max-width: $sizeMd){
    font-size: 2rem;
  }
}
.timeline-article .content-left-container {
  float: left;
}
.timeline-article .content-right-container {
  float: right;
}
.timeline-article .content-left:before,
.timeline-article .content-right:before{
  position: absolute;
  top: 1.25rem;
  font-size: 1.438rem;
  font-family: "FontAwesome";
  color: #fff;
}

.timeline-article .meta-date {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  color: #fff;
  border-radius: 100%;
  background: #1d1d1b;
}

.conference-timeline-content{
  .timeline-logo{
    img{
      max-height: 12rem;
      margin: 0 auto;
    }
  }

  .text-left{
    text-align: left;
  }

  .text-right{
    text-align: right;
  }
}
/*===== // Resonsive Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  .timeline-start{


    .meta-date{
      position: absolute;
      top: 100%;
      left: calc(50% - 1rem);
      width: 2rem;
      height: 2rem;
      color: #fff;
      border-radius: 100%;
      background: #1d1d1b;
    }
  }
}