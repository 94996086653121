
$clients: 'tcl', 'calimax', 'driscolls', "navico", "envases", "andrew-williamson", "parpro";


#listado-clientes{
  .grid {
    display: grid;
    gap: 0;
    grid-template-columns: repeat(3, 1fr);

    & > div{

      @each $client in $clients {
        .#{$client} {
          .flip-card-front,
          .flip-card-back{
            background-size: cover;
            background-position: center;
          }
          .flip-card-front{
            background-image: url("./../../../images/clientes/#{$client}.jpg");
          }

          .flip-card-back{
            background-image: url("./../../../images/clientes/#{$client}-active.jpg");
          }
        }
      }
    }
  }

}
