@import 'variables.scss';

@font-face {
    font-family: 'Gotham';
    font-weight: 300;
    src: url(./fonts/Gotham-Thin.otf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    src: url(./fonts/Gotham-XLight.otf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 500;
    src: url(./fonts/Gotham-Light.otf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 600;
    src: url(./fonts/Gotham-Bold.otf) format('truetype');
    font-display: swap;
}


@font-face {
    font-family: 'Gotham';
    font-weight: 700;
    src: url(./fonts/Gotham-Black.otf) format('truetype');
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Gotham', 'Arial',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


a {
    color: inherit !important;
}

.main {
    background-color: #f2f2f1;
    background-image: url("./images/paralax-logo.png");
    background-position: center;
    background-size: 17.5vw;

    background-attachment: fixed;
    background-repeat: no-repeat;

    @include tablet{
        .container{
            max-width: 95vw;
        }
    }
}


@media only screen and (max-device-width: 1366px) {
    .parallax {
        background-attachment: scroll;
    }
}

.main.container-fluid {
    position: relative;
}


[data-aos="fade-in"].aos-animate {
    animation: fadeIn ease 2250ms;
    -webkit-animation: fadeIn ease 2250ms;
    -moz-animation: fadeIn ease 2250ms;
    -o-animation: fadeIn ease 2250ms;
    -ms-animation: fadeIn ease 2250ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

p{
    font-size: 1.1rem;
}