@import './../../../variables.scss';

#historia{
  @media(min-width: $sizeMd){
    h2{
      font-size: 2rem;
    }

  }

  .historia-thumb{
    p{
      font-size: 1rem;
      line-height: 1.2;
    }
    .year{
      font-size: 1.4rem;
      font-weight: 600;
    }

    .description{

      @media(max-width: $sizeMd){
        img{
          max-width: 80%;
        }
      }
      @media(min-width: $sizeMd){
        max-height: 13rem;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.2rem;
      }
    }
    @media(min-width: $sizeMd){
      img{
        max-width: 60%;
        margin-bottom: 1.5rem;
      }
    }
  }
}