@import './../../../variables.scss';

.agencia {
  @include tablet{
    .bg-light-gray{
      background-color: transparent;
    }
  }
}

.tu-mejor-eleccion-img:after{
  content: "";
  width: 100%;
  height: 12rem;
  display:block;
  background-image: url(./../../../images/agencia/agencia-personas.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include mobile(){
    margin-top: 2rem;
  }
}
