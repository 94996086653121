@import './../../variables.scss';
.cotizacion-strip{
  color: #fff;
  text-align: right;
  background-position: center;
  background-size: cover;
  background-color: black;
  border-bottom: 2px solid white;
  text-align: left!important;

  &.agencia{
    background-image: url("../../images/agencia/solicita-cotizacion-bg.jpg") !important;
  }

  &.freight{
    background-image: url("../../images/freight/solicita-cotizacion-bg.jpg") !important;
  }

  &.transporte{
    background-image: url("../../images/transporte/transporte-cotizacion-bg.jpg") !important;
  }

  &.agencia,
  &.transporte,
  &.freight{
    .btn-filled:not(.btn-no-arrow){
      color: #1d1d1b!important;
      &:after {
        content: "";
        background-image: url("../../images/icons/filled-btn-arrow-black.png");
      }

      &:hover:after {
        content: "";
        background-image: url("../../images/icons/filled-btn-arrow.png")!important;
      }
    }
  }

  .container{
    position: relative;
    .cotizacion-logo{
      position: absolute;
      right:2rem;
      bottom:2rem;
      max-width: 3rem;
      display:none;
      @media(min-width: $sizeLg){
        display:block;
      }
    }
  }
}
