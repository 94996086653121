@import './../../../variables.scss';

.valores{
  .container{
    border-left: 2px solid #dadada;
    border-right: 2px solid #dadada;
  }
  background-color: #414447;
  color: #dadada;
  @media(min-width: $sizeLg){
    padding-right: 4rem;
  }

  @include mobile{
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .transport-icon{

    .transport{
      background-image: url("../../../images/transporte/trailer.png");
      content: "";
      background-size: contain;
      width: 5.65rem;
      position: absolute;
      height: 80%;
      display: block;
      min-height: 8vw;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      margin: 0 auto;
      left:0;
      right:0;

      @include mobile{
        width: 3.65rem;
      }
      &.slide-0{
        top: 1.5rem;
      }

      &.slide-1{
        top: 3rem;
      }

      transition: all 0.8s ease-in-out;
    }
    &:before{
      content: " ";
      height: 100%;
      display:block;
      position:absolute;
      border: 2px solid #FDBF23;
      border-top: 0;
      border-bottom: 0;
      width: 0.5rem;
      z-index: 0;
    }
  }
  .valores-slide{
    .slide{
      @media(min-width: $sizeMd){
        padding-right: 2rem;
        p{
          font-size: 1rem;
        }
      }
      @include mobile{
        min-height: 15.75rem;
        p, li{
          font-size: 0.8rem;
        }
      }

      @include tablet{
        @media(orientation: landscape){
          min-height: 13.75rem;
        }
        @media(orientation: portrait){
          min-height: 16.70rem;
        }
      }

      @media(min-width: $sizeLg){
        min-height: 10.75rem;
      }
    }
    .slick-arrow{
      background-color: transparent;
      position: absolute;

      &.slick-next,
      &.slick-prev{
        left: calc(100% + 0.5rem);
        width: 2rem;
        height: 2rem;

        background-size: contain;
        &.slick-prev{
          top: calc(50% - 1.25rem);
          background-image:  url("../../../images/transporte/arrow-circle-up.png");
        }
        &.slick-next{
          top: calc(50% + 1.25rem);
          background-image:  url("../../../images/transporte/arrow-circle-down.png");
        }

        @media(min-width: $sizeMd){
          left: calc(100% + 1rem);
        }
      }
    }
  }
}
