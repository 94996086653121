@import './../../../variables.scss';

#sucursales{
  background-image: url("./../../../images/grupo/bg-sucursales.jpg") !important;
  background-size:cover;
  color: #fff;
  .text-small{
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  img{
    margin-top: 1rem;
    @media(min-width: $sizeLg){
      margin-top: -1rem;
    }
  }

  .right-map{
    @media(max-width: $sizeMd){
      display: flex;
      align-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    @media(min-width: $sizeMd){
      position: relative;
      img{
        position:absolute;
      }
    }
  }
}
