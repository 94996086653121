@import './../../variables.scss';
.contact-strip{
    background-image: url("../../images/contact-strip-bg.png");
    color: #ffffff;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    position: absolute;
    bottom:0;
    width:100%;

    .mobile-details{
        font-size: 0.7rem;
        line-height: 1;
        text-align: left!important;
    }

    @include tablet{
        .container{

            .exchange{
                max-width: 95vw;



                @media(orientation: portrait){
                    max-width: 100vw;
                    font-size: 90%;
                }

                @include ipadAir{
                    *{
                        font-size: 100%;
                    }

                }

            }

            @media(orientation: portrait){
                max-width: 98vw;
                font-size: 90%;
            }
        }
    }

    @include tablet{
        line-height: 1.3;
    }
}
.contact-strip .icon{
    background-repeat: no-repeat;
    padding-left: 5rem;

    @media(max-width: $sizeSm){
        padding-left: 1.15rem;

        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    @include tablet{
        padding-left: 3.5rem;
    }
}

.contact-strip .phone-call,
.contact-strip .home-location,
.contact-strip .at-email
{
    font-size: 1.188rem;
}

.contact-strip .phone-call strong,
.contact-strip .home-location strong,
.contact-strip .at-email strong
{
    font-size: 1.313rem;
}

@media (min-width: $sizeXs) and (max-width: $sizeSm){
    .contact-strip .phone-call strong, .contact-strip .home-location strong, .contact-strip .at-email strong{
        font-size: 0.95rem!important;
    }
}
@media(max-width: $sizeXs){
    .contact-strip strong
    {
        font-size: 0.82rem!important;
    }
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 0.82rem!important;
        line-height: 1;
    }

    .home-location{
        padding-left: 1rem!important;
    }

    @media(max-width: $sizeXxs){
        .contact-strip strong
        {
            font-size: 0.62rem!important;
        }
        .contact-strip .phone-call,
        .contact-strip .home-location,
        .contact-strip .at-email
        {
            font-size: 0.62rem!important;
            line-height: 1;
        }

    }
}


@media(min-width: $sizeXs) and (max-width: $sizeSm){
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 0.92rem!important;
        line-height: 1;
    }

    .home-location{
        padding-left: 1rem!important;
    }
}

@include tablet{
    .contact-strip .phone-call strong, .contact-strip .home-location strong, .contact-strip .at-email strong{
        font-size: 0.85rem!important;
    }
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 0.85rem;
        padding-left:1rem;
        padding-right: 0rem;
        background-size: 1.8rem;
        background-position: center left;

        @include  tablet{
            @media(orientation: portrait){
                background-size: 1.6rem;
            }
        }
    }

    @media(orientation: portrait){
        .contact-strip .at-email{
            background-position: 1.8rem;
        }
    }
}

.empleo {
    @include tablet {
        @media(orientation: portrait){
            .contact-strip .at-email{
                background-position: 1.5rem!important;
            }
        }
    }
}

@media (min-width: $sizeMd) and (max-width: $sizeLg){
    .contact-strip .phone-call strong, .contact-strip .home-location strong, .contact-strip .at-email strong{
        font-size: 1rem!important;
    }
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 1rem;
    }
}

@media (min-width: $sizeLg){
    .contact-strip .phone-call,
    .contact-strip .home-location,
    .contact-strip .at-email
    {
        font-size: 1.15rem;
    }
}

.contact-strip .phone-call{
    background-image: url("../../images/icons/whats.png");
}

.contact-strip .home-location{
    background-image: url("../../images/icons/home-location.png");
}

.contact-strip .at-email{
    background-image: url("../../images/icons/at-email.png");
}

.contact-strip a{
    text-decoration: none;
}
.bordered-text{
    border:  0.063rem  solid #fff;
    padding: 0.188rem 0.938rem;
    border-radius:  0.625rem;
    font-weight: 600;
}

.bordered-transparent{
    border:  0.063rem  solid #fff;
    padding: 0.188rem 0.938rem;
    border-radius:  0.625rem;
    width: fit-content;
}

.exchange{
    font-weight: 600;
    align-items: center;
    display: flex;
    font-size: 1.063rem;

    .label {
        @media(max-width: $sizeSm){
            font-size: 1.4rem;
            padding-left: 1.2rem;
            text-align: left;
        }
        @media(min-width: $sizeSm){
            text-align: right;
            font-size: 1.765rem;
            padding-right: 1.7rem;

            @include tablet{
                padding: 0 0.25rem 0 0;
                font-size: 1.45rem;
            }
        }
    }

    .bordered-text{
        min-width: 6.25rem!important;
        display:block;
    }

    .price{
        color: #fcbf24;
        font-size: 1.25rem;
        line-height: 1.875rem;
        vertical-align: text-top;

        @include ipadAir{
            @media(orientation: portrait){
                line-height: 2.8rem;
            }
        }
    }

    .date{
        background-color: #575656;
        padding: 0.188rem 0.938rem;
        border-radius: 0.625rem;

        @media(max-width: $sizeXs){
            padding: 0.188rem 0.438rem;
            font-size: 0.9rem;

            @media(max-width: $sizeXxs){
                font-size: 0.8rem;
            }
        }

        @include tablet{
            @media(orientation: portrait){
                padding: 0.188rem 0.238rem;
            }
        }
    }
}

@media(min-width: $sizeMd){
    .contact-strip .at-email{
        background-position: 3.8vw!important;
    }
    .contact-strip .icon {
        text-align: left;
        background-size: 2vw;
        background-position: 1.8vw;
    }

    @include tablet{
        .contact-strip .at-email{
            background-position: 3.8vw!important;
        }
        .contact-strip .icon {
            text-align: left;
            background-size: 2vw;
            background-position: 1.8vw;

            @media(orientation: landscape){
                &.strong,
                &{
                    font-size: 1rem;
                    line-height: 1.2rem;
                }
            }
        }
    }
}




@media(max-width: $sizeMd){
    .contact-strip{
        text-align: left;
        background-position: 3.5vw;
    }
}

.vr{
    background-color: #777676;
}
