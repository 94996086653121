@import './../../../variables.scss';

.contador{
  @property --num {
    syntax: '<integer>';
    inherits: true;
    initial-value: 0;
  }

  div::before {
    counter-reset: my-counter var(--num);
    content: counter(my-counter);
    animation: count 10s ease-in-out infinite alternate;
  }

  @keyframes count {
    to { --num: 100; }
  }
}

#counter-strip{
  margin: 0.2rem 0;
  color: #fff;
  text-align: right;
  background-position: center;
  background-size: cover;

  .contador{
    h6{
      color: #eec43f;
      font-size: 2.5rem;
      font-weight: 400;
      margin-bottom:0;
      padding-bottom:0;
      @media(max-width: $sizeMd){
        font-size: 2rem;
      }
    }

    p{
      font-size: 0.9rem;
    }
    @media(max-width: $sizeSm){
      p{font-size: 0.64rem; line-height: 1.2}
      h6{font-size: 1.8rem;}
    }
    @media (min-width: $sizeSm) and (max-width: $sizeMd){
      p{font-size: 0.84rem; line-height: 1.2}
      h6{font-size: 2rem;}
    }
  }

  &.almacen{
    background-image: url("./../../../images/grupo/bg-almacen.jpg") !important;
  }

  &.transporte{
    background-image: url("./../../../images/grupo/bg-transporte.png") !important;
  }
}